import React from "react"
import ShipmentHistory from "../components/ShipmentHistory"
import { Box, Tabs, Tab } from "@mui/material"
import TabPanel from "../components/TabPanel"
import UnderConstruction from "../components/UnderConstruction"

const Shipments = () => {
    const [value, setValue] = React.useState(0)

    const handleChange = (event, newValue) => {
        setValue(newValue)
    }

    const a11yProps = index => {
        return {
            id: `shipments-tab-${index}`,
            "aria-controls": `shipments-tabpanel-${index}`,
        }
    }

    return (
        <>
            <Box>
                <Box sx={{ borderBottom: 1, borderColor: "divider" }}>
                    <Tabs value={value} onChange={handleChange}>
                        <Tab label="Отгрузки в работе" {...a11yProps(0)} />
                        <Tab label="Архив отгрузок" {...a11yProps(1)} />
                    </Tabs>
                </Box>
                <TabPanel value={value} index={0} aria="shipments">
                    <ShipmentHistory />
                </TabPanel>
                <TabPanel value={value} index={1} aria="shipments">
                    <ShipmentHistory status={"В архиве"} />
                </TabPanel>
            </Box>
        </>
    )
}

export default Shipments
